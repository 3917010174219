import { useState, useCallback } from "react"
import { User } from "@firebase/auth"
import { useAppDispatch, useAppSelector } from "app/store"
import useNoSleep from "shared/hooks/use-no-sleep"
import { mainScreenActions } from "../../store/main-screen.slice"
import { setMeditationThunk } from "../../store/main-screen.thunks"
import { TimerButton } from "../timer-button/timer-button.component"
import { Countdown } from "../countdown/countdown.component"
import { Sound } from "../sound.component"
import { Tips } from "../tips"
import { BottomTextWrapper, Wrapper } from "./timer.styles"
import { SECS_IN_MIN } from "shared/constants"
import { Seconds } from "shared/types"
import { selectIsTimerStarted } from "../../store/main-screen.selectors"

interface Props {
  user: User
  authLoading: boolean
}

// TODO: refactor component
export const Timer: React.FC<Props> = ({ user, authLoading }) => {
  const [currentTimerId, setCurrentTimerId] = useState<number | null>(null)
  const [timerDiff, setTimerDiff] = useState<Seconds>(0)
  const isTimerStarted = useAppSelector(selectIsTimerStarted)

  useNoSleep(isTimerStarted)
  const dispatch = useAppDispatch()

  const finishTimer = useCallback(
    async (timerDiff: Seconds): Promise<void> => {
      const isCurrentTimerIdExist = currentTimerId !== null
      if (!isCurrentTimerIdExist) throw Error("currentTimerId is not exist")

      window.clearInterval(currentTimerId)

      dispatch(mainScreenActions.setTimerStatus(false))
      setTimerDiff(0)

      const isSessionLongerThanMinute = timerDiff > SECS_IN_MIN
      if (!isSessionLongerThanMinute) {
        return
      }

      try {
        // NOTE: line below for fast debugging
        // dispatch(setMeditationThunk({ user, seconds: 61 }))
        dispatch(setMeditationThunk({ user, seconds: timerDiff }))
      } catch (e) {
        console.error(e)
      }
    },
    [currentTimerId, dispatch, user]
  )

  const handleTimer = useCallback((startTime: Seconds) => {
    const secondsNow = Math.round(Date.now() / 1000)
    const secondsDiff = secondsNow - startTime
    setTimerDiff(secondsDiff)
  }, [])

  const startTimer = useCallback(() => {
    const startInSeconds = Math.round(Date.now() / 1000)
    dispatch(mainScreenActions.setTimerStatus(true))

    const newTimerId = window.setInterval(
      () => handleTimer(startInSeconds),
      100
    )
    setCurrentTimerId(newTimerId)
  }, [dispatch, handleTimer])

  const handleClick = useCallback(() => {
    setTimerDiff(0)

    if (isTimerStarted) {
      return finishTimer(timerDiff)
    } else {
      return startTimer()
    }
  }, [finishTimer, isTimerStarted, startTimer, timerDiff])

  return (
    <Wrapper>
      <TimerButton
        handleTimerClick={handleClick}
        isTimerStarted={isTimerStarted}
        authLoading={authLoading}
      >
        {!authLoading && <Countdown seconds={timerDiff} />}
        <Sound progress={timerDiff} />
      </TimerButton>

      <BottomTextWrapper>
        {!authLoading && (
          <Tips
            seconds={timerDiff}
            isTimerStarted={isTimerStarted}
          />
        )}
      </BottomTextWrapper>
    </Wrapper>
  )
}
