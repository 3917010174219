import { auth } from "features/home/firebase-init"
import { StyledButton } from "shared/components/styled-button.styles"

export const SignOut = () => {
  const signOut = () => auth.signOut()

  return (
    auth.currentUser && (
      <StyledButton
        type="button"
        onClick={signOut}
      >
        Sign out
      </StyledButton>
    )
  )
}
