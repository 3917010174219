export interface SettingsData {
  darkMode: boolean
  timerBlinking: boolean
  sound: boolean
  progression: ProgressionType
  progressionRatio: number
}

export enum ProgressionType {
  DEFAULT = "default",
  FIBONACCI = "fibonacci",
  LINEAR = "linear"
}

export interface SettingsState extends SettingsData {}
