import { TABLET_MIN_WIDTH } from "shared/constants"
import styled, { keyframes, css } from "styled-components"

const fading = keyframes`
  0% { opacity: 0%; }
  3% { opacity: 100%; }
  60% { opacity: 100%; }
  100% { opacity: 0%; }
`

const animation = css<Props>`
  animation: ${fading} 1s ease-in-out infinite;
`

interface Props {
  blinking: boolean
}
export const StyledCountdown = styled.span<Props>`
  margin: 0;
  font-family: var(--font-mono);
  font-size: 5.5rem;
  line-height: 1;
  cursor: help;
  color: var(--c-foreground);
  -webkit-touch-callout: none !important;
  ${({ blinking }) => (blinking ? animation : "animation: none;")}

  @media screen and (max-width: ${TABLET_MIN_WIDTH}) {
    margin: 0;
  }
`
