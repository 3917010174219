import { ProgressionType } from "features/settings/settings.types"
import {
  EXPONENTIAL_STAGES,
  FIBONACCI_STAGES,
  LINEAR_STAGE_RATIOS
} from "shared/constants"

export const getProgressionByType = (type: ProgressionType, ratio?: number) => {
  switch (type) {
    case ProgressionType.DEFAULT:
      return EXPONENTIAL_STAGES
    case ProgressionType.FIBONACCI:
      return FIBONACCI_STAGES
    case ProgressionType.LINEAR:
      return LINEAR_STAGE_RATIOS.map((num) => num * (ratio || 5))
    default:
      return LINEAR_STAGE_RATIOS.map((num) => num * 1)
  }
}
