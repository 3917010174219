import React, { useEffect, useState } from "react"
import { CheckMark } from "shared/components/check-mark/check-mark.component"
import { StyledTooltip } from "shared/components/styled-tooltip.styles"
import {
  getClosestProgressionDiscrete,
  getProgressionByType
} from "shared/utils"
import { useAppSelector } from "app/store"
import { StyledCountdown } from "./countdown.styles"
import { remainTimeToDigitClock } from "./remainTimeToDigitClock"
import { Minutes, Seconds } from "shared/types"
import getRemainTime from "./get-remain-time"

interface Props {
  seconds: number
  checkmarkAnimation?: boolean
}

export const Countdown: React.FC<Props> = ({
  seconds,
  checkmarkAnimation = true
}) => {
  const [secondsRemain, setSecondsRemain] = useState<Seconds>(0)
  const [minutesRemain, setMinutesRemain] = useState<Minutes>(0)
  const [timeRemain, setTimeRemain] = useState("00:00")
  const [isBlinkingStarted, setIsAnimationEnabled] = useState(false)

  const status = useAppSelector((state) => state.mainScreen.requestStatus)
  const timerBlinkingSetting = useAppSelector(
    (state) => state.settings.timerBlinking
  )
  const progressionType = useAppSelector((state) => state.settings.progression)
  const progression = getProgressionByType(progressionType)

  const timerProgressToCountdown = React.useCallback(
    (seconds: Seconds) => {
      const closestDiscreteStage = getClosestProgressionDiscrete(
        seconds,
        progression
      )

      for (const num of progression) {
        if (closestDiscreteStage === num) {
          const { seconds: secondsRemain, minutes } = getRemainTime(
            closestDiscreteStage,
            seconds,
            progression
          )
          setSecondsRemain(secondsRemain)
          setMinutesRemain(minutes)
        }
      }
    },
    [progression]
  )

  useEffect(() => {
    if (seconds) {
      timerProgressToCountdown(seconds)
    }
  }, [seconds, timerProgressToCountdown])

  useEffect(() => {
    if (seconds === 0) {
      setSecondsRemain(0)
      setMinutesRemain(0)
    }

    setTimeRemain(remainTimeToDigitClock(secondsRemain, minutesRemain))
  }, [minutesRemain, seconds, secondsRemain])

  useEffect(() => {
    if (seconds === 0) return
    setIsAnimationEnabled(true)
    return () => setIsAnimationEnabled(false)
  }, [seconds])

  const blinking = timerBlinkingSetting && isBlinkingStarted

  return (
    <>
      <StyledCountdown
        blinking={blinking}
        key={timeRemain}
      >
        {timeRemain}
      </StyledCountdown>

      {status === "loaded" && <CheckMark />}
    </>
  )
}
