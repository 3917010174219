import { useMemo } from "react"
import { useAppSelector } from "app/store"
import { StatsChart } from "./components/stats-chart/stats-chart.component"
import { StatsNumbers } from "./components/stats-numbers/stats-numbers.component"
import { Wrapper } from "./user-stats.styles"
import { getUserChartData } from "./user-stats.utils"
import { StyledSpinner } from "shared/components/styled-spinner.styles"
import { NoUserStatsMessage } from "./components/no-user-stats-message"
import {
  selectDaysData,
  selectIsLoading,
  selectUserStats
} from "./store/user-stats.selectors"
import styled from "styled-components"

interface Props {
  authLoading: boolean
}

// TODO: rename to StatisticsPage
export const UserStats: React.FC<Props> = ({ authLoading }) => {
  const userStatistics = useAppSelector(selectUserStats)
  const userDaysData = useAppSelector(selectDaysData)
  const isLoading = useAppSelector(selectIsLoading)

  const userChartData = useMemo(() => {
    if (!userStatistics || !userDaysData) return null
    return getUserChartData(userDaysData, userStatistics)
  }, [userDaysData, userStatistics])

  const isChartDataExist = userChartData?.[0].data.length !== 0

  return (
    <Wrapper>
      {authLoading || isLoading ? (
        <StyledSpinner />
      ) : isChartDataExist ? (
        <ChartWrapper>
          <StatsNumbers statsData={userStatistics} />
          <StatsChart chartData={userChartData} />
        </ChartWrapper>
      ) : (
        <NoUserStatsMessage />
      )}
    </Wrapper>
  )
}

const ChartWrapper = styled.div`
  min-width: 28rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`
