import useSound from "use-sound"
import React from "react"
import bellSfx from "shared/assets/sounds/meditation-bell-sound.mp3"
import { EXPONENTIAL_STAGES } from "shared/constants"
import { useAppSelector } from "app/store"

interface Props {
  progress: number
}

export const Sound: React.FC<Props> = ({ progress }) => {
  const [playSound] = useSound(bellSfx, {
    volume: 0.3
  })
  const sound = useAppSelector((state) => state.settings.sound)

  // TODO: remake in custom hook instead of component
  React.useEffect(() => {
    if (progress === 0) return

    const isProgressionNum = EXPONENTIAL_STAGES.includes(progress)
    if (sound && isProgressionNum) {
      return playSound()
    }
  }, [progress, playSound, sound])

  return null
}
