import { Hours, Minutes, PokoySession, Seconds } from "shared/types"
import { roundToHundredth } from "./round-to-nth"
import { SECS_IN_MIN } from "shared/constants"

export { getFloorProgressionDiscrete as getFloorFibonacciDiscrete } from "./get-floor-fibonacci-discrete"
export { roundToHundredth, roundToTenth } from "./round-to-nth"
export { showAppVersion } from "./show-app-version"
export {
  serverDayDataToStoreAdapter,
  serverStatsDataToStoreAdapter
} from "./adapters"
export { getProgressionByType } from "./get-progression-by-type"

export const getClosestProgressionDiscrete = (
  seconds: Seconds,
  progression: Seconds[]
): Seconds => {
  const progressionDiscrete = progression.reduce(
    (acc: Seconds, progressionNum: Seconds) => {
      const currDiff: Seconds = Math.abs(progressionNum - seconds)
      const prevDiff: Seconds = Math.abs(acc - seconds)
      const isCloserToDiscrete = currDiff < prevDiff

      return isCloserToDiscrete ? progressionNum : acc
    },
    Infinity as Seconds
  )

  return progressionDiscrete
}

type TimeUnits = Hours | Minutes | Seconds
export function getNextProgressionStage<T extends TimeUnits>(
  currentStage: T,
  timeUnits: T,
  progression: T[]
): T {
  const nextStageIndex = progression.indexOf(currentStage) + 1
  const nextStage =
    currentStage > timeUnits ? currentStage : progression[nextStageIndex]
  return nextStage
}

export function getFloorProgressionDiscrete<T extends TimeUnits>(
  timeUnits: T,
  progression: T[]
) {
  const checkDiscrete = (acc: number, fibNum: number, index: number) => {
    const curDiff = Math.abs(fibNum - timeUnits)
    const prevDiff = Math.abs(acc - timeUnits)
    const isLessThanMinutes = timeUnits >= fibNum
    const isCloserToDiscrete = curDiff < prevDiff
    const closestFib = isCloserToDiscrete ? fibNum : acc

    return isLessThanMinutes ? closestFib : acc
  }

  const fibDiscrete = progression.reduce(checkDiscrete, 0)

  return fibDiscrete
}

export const createSessionData = (
  userId: string,
  seconds: number
): PokoySession => {
  const timestamp = new Date().toISOString()
  const duration = roundToHundredth(seconds / SECS_IN_MIN)

  return {
    userId,
    timestamp,
    duration
  }
}
