import styled from "styled-components/macro"

export const Wrapper = styled.div`
  display: grid;
  max-height: 100%;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: min-content;
  grid-template-areas:
    "timerButton"
    "tips";
  flex-direction: column;
  align-items: end;
  justify-content: center;
  justify-items: center;
  padding: 0 1rem;
  row-gap: 3rem;
`

export const BottomTextWrapper = styled.div`
  grid-area: tips;
  margin: 0;
  width: 100%;
  align-self: flex-start;
`
