import { Milliseconds, Minutes, Seconds } from "shared/types"

export const MILLIS_IN_SECOND = 1000
export const SECS_IN_MIN = 60
/** NOTE: equal to 21 minutes */
export const MAX_TIMER_SECONDS: Seconds = SECS_IN_MIN * 21
export const MINS_IN_HOUR: Minutes = 60
export const SECS_IN_HOUR: Seconds = SECS_IN_MIN * MINS_IN_HOUR
export const SECS_IN_DAY: Seconds = SECS_IN_HOUR * 24
export const MILLIS_IN_DAY: Milliseconds = SECS_IN_DAY * MILLIS_IN_SECOND
export const MILLIS_IN_QUARTER: Milliseconds = MILLIS_IN_DAY * 91

export const INITIAL_MEDITATION_DURATION: Seconds = 0
