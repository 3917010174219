import { SlideRenderProps } from "react-swipeable-views-utils"
import { SwipeableView } from "shared/styles/app.styles"
import { TimerButton } from "features/home/components/timer-button/timer-button.component"
import { Countdown } from "features/home/components/countdown/countdown.component"
import styled from "styled-components"
import { CheckMark } from "shared/components/check-mark/check-mark.component"
import { StatsNumbers } from "features/user-stats/components/stats-numbers/stats-numbers.component"
import { MILLIS_IN_DAY } from "shared/constants"

export const tutorialSlideRenderer = ({ index }: SlideRenderProps) => {
  switch (index) {
    case 0:
      return <TutorialFirstStep key={index} />
    case 1:
      return <TutorialSecondStep key={index} />
    case 2:
      return <TutorialThirdStep key={index} />
    case 3:
      return <TutorialFourthStep key={index} />
    case 4:
      return <TutorialFifthStep key={index} />
    default:
      return <p>This step doesn't yet exist 🤷‍♂️</p>
  }
}

const TutorialFirstStep: React.FC = () => {
  return (
    <SwipeableView>
      <h2>Start of the App</h2>
      <TutorialDemoWrapper>
        <TimerButton
          handleTimerClick={() => {}}
          isTimerStarted={false}
          authLoading={false}
        >
          <Countdown seconds={0} />
        </TimerButton>
      </TutorialDemoWrapper>
      <p>You start the application and see a button with a timer.</p>
    </SwipeableView>
  )
}

const TutorialSecondStep: React.FC = () => {
  return (
    <SwipeableView>
      <h2>The Timer</h2>
      <TutorialDemoWrapper>
        <TimerButton
          handleTimerClick={() => {}}
          isTimerStarted={true}
          authLoading={false}
        >
          {<Countdown seconds={1} />}
        </TimerButton>
      </TutorialDemoWrapper>
      <p>
        You click on the button and the session begins. A one-minute countdown
        timer will appear.
      </p>
    </SwipeableView>
  )
}

const TutorialThirdStep: React.FC = () => {
  return (
    <SwipeableView>
      <h2>Increasing Stages</h2>
      <TutorialDemoWrapper>
        <TimerButton
          handleTimerClick={() => {}}
          isTimerStarted={true}
          authLoading={false}
        >
          {<Countdown seconds={6540} />}
        </TimerButton>
      </TutorialDemoWrapper>
      <p>
        The first stages are short, and then the duration of each stage grows.
      </p>
    </SwipeableView>
  )
}

const TutorialFourthStep: React.FC = () => {
  return (
    <SwipeableView>
      <h2>End of session</h2>

      <TutorialDemoWrapper>
        <TimerButton
          handleTimerClick={() => {}}
          isTimerStarted={true}
          authLoading={false}
        >
          <Countdown seconds={0} />
          <div
            style={{
              justifySelf: "end",
              alignSelf: "center",
              height: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "6px"
            }}
          >
            <CheckMark animation={false} />
          </div>
        </TimerButton>
      </TutorialDemoWrapper>

      <p>
        After you finish each step, you will hear a bell and move on to the next
        step, until you press the button again.
      </p>
      <p>
        After the second click, your session is over. The session time will be
        recorded in the statistics.
      </p>
    </SwipeableView>
  )
}

const TutorialFifthStep: React.FC = () => {
  const todayTimestamp = Date.now()

  return (
    <SwipeableView>
      <h2>Statistics</h2>
      <TutorialDemoWrapper>
        <StatsNumbers
          statsData={{
            firstMeditationDate: todayTimestamp - MILLIS_IN_DAY * 30,
            totalDuration: 1000,
            count: 2000,
            userId: "abc"
          }}
        />
      </TutorialDemoWrapper>

      <p>
        If you swipe to the right or press the screen switcher (••), you will be
        taken to your statistics screen.
      </p>
      <p>
        Here you will see the amount of practice in hours, the average session
        in minutes, and how many days until the next practice level.
      </p>
    </SwipeableView>
  )
}

const TutorialDemoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 5rem;
  border: 0.25rem solid var(--c-darken-gray);
  border-radius: 2rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
`
