import styled from "styled-components/macro"
import { settingsActions } from "../store/settings.slice"
import { useAppDispatch, useAppSelector } from "app/store"
import {
  EXPONENTIAL_STAGES,
  FIBONACCI_STAGES,
  LINEAR_STAGE_RATIOS,
  SECS_IN_MIN
} from "shared/constants"
import { useCallback } from "react"
import { ProgressionType } from "../settings.types"
import { StyledButton } from "shared/components/styled-button.styles"
import { ReactComponent as ExportIcon } from "shared/assets/svgs/export-icon.svg"
import { exportToCSV } from "features/user-stats/user-stats.utils"
import { selectDaysData } from "features/user-stats/store/user-stats.selectors"

export const SettingsControls: React.FC = () => {
  const dispatch = useAppDispatch()

  const timerBlinking = useAppSelector((state) => state.settings.timerBlinking)
  const sound = useAppSelector((state) => state.settings.sound)
  const progression = useAppSelector((state) => state.settings.progression)
  const progressionRatio = useAppSelector(
    (state) => state.settings.progressionRatio
  )
  const darkMode = useAppSelector((state) => state.settings.darkMode)
  const daysData = useAppSelector(selectDaysData)

  const getProgressionNumbers = useCallback((progression: number[]): string => {
    return (
      progression
        .slice(1, 5)
        .map((n) => n / SECS_IN_MIN)
        .join(", ") + "..."
    )
  }, [])

  const exportToCsvOnClick = useCallback(() => {
    window.open(exportToCSV(daysData))
  }, [daysData])

  return (
    <SettingsWrapper>
      <StyledExportButton
        type="button"
        onClick={exportToCsvOnClick}
        fontSize="2rem"
      >
        <ExportIcon
          color={"currentColor"}
          style={{
            width: "3rem",
            height: "3rem",
            marginRight: "1rem"
          }}
        />
        Statistics Export
      </StyledExportButton>

      {/* <hr style={{ width: "100%" }} /> */}

      <StyledLabel>
        <span>Dark mode (beta)</span>
        <StyledCheckbox
          checked={darkMode}
          onChange={() => dispatch(toggleDarkMode())}
        />
      </StyledLabel>

      <StyledLabel>
        <span>Timer blinking</span>
        <StyledCheckbox
          checked={timerBlinking}
          onChange={() => dispatch(toggleTimerBlinking())}
        />
      </StyledLabel>

      <StyledLabel>
        <span>Sound</span>
        <StyledCheckbox
          checked={sound}
          onChange={() => dispatch(toggleSound())}
        />
      </StyledLabel>

      <StyledLabel>
        <span>Progression type</span>
        <StyledSelect
          value={progression}
          onChange={({ target: { value } }) =>
            dispatch(setProgression(value as ProgressionType))
          }
        >
          <option value={ProgressionType.DEFAULT}>
            Default: {getProgressionNumbers(EXPONENTIAL_STAGES)}
          </option>

          <option value={ProgressionType.FIBONACCI}>
            Fibonacci: {getProgressionNumbers(FIBONACCI_STAGES)}
          </option>

          <option value={ProgressionType.LINEAR}>
            Linear:{" "}
            {getProgressionNumbers(
              LINEAR_STAGE_RATIOS.map((n) => n * progressionRatio)
            )}
          </option>
        </StyledSelect>
      </StyledLabel>

      {progression === "linear" ? (
        <StyledLabel>
          <span>Progression ratio</span>
          <StyledInput
            type="number"
            min="1"
            max="30"
            value={progressionRatio}
            onChange={(e) => dispatch(setProgressionRatio(+e.target.value))}
          />
        </StyledLabel>
      ) : null}
    </SettingsWrapper>
  )
}

const {
  setProgression,
  setProgressionRatio,
  toggleSound,
  toggleTimerBlinking,
  toggleDarkMode
} = settingsActions

const SettingsWrapper = styled.div`
  margin: 10rem 0;
  display: flex;
  row-gap: 2.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 50%;
`

const StyledLabel = styled.label`
  display: grid;
  font-size: 2.5rem;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: max-content min-content;
  /* column-gap: 2rem; */
  width: 100%;
  flex: 1 0 auto;
`

const StyledCheckbox = styled.input.attrs(() => ({
  type: "checkbox"
}))`
  width: 3rem;
  aspect-ratio: 1;
`

const StyledSelect = styled.select`
  max-width: 20rem;
`

const StyledInput = styled.input`
  max-width: 20rem;
`

const StyledExportButton = styled(StyledButton)`
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
`
