import React, { useCallback } from "react"
import { getFloorProgressionDiscrete } from "shared/utils/get-floor-fibonacci-discrete"
import { getNextProgressionStage, getProgressionByType } from "shared/utils"
import {
  StageNumber,
  StageWrapper,
  StyledDesc,
  StyledTip,
  StyledTrianle,
  StyledUnits,
  Wrapper
} from "./tips.styles"
import { EXPONENTIAL_STAGES } from "shared/constants"
import { Minutes, Seconds } from "shared/types"
import { useAppSelector } from "app/store"

interface Props {
  seconds: Seconds
  isTimerStarted: boolean
}

// TODO: refactor component
export const Tips: React.FC<Props> = React.memo(
  ({ seconds, isTimerStarted }) => {
    const [currentStage, setCurrentStage] = React.useState(0)
    const progressionType = useAppSelector(
      (state) => state.settings.progression
    )

    const getNextStage = useCallback(getNextProgressionStage, [])
    const progression = getProgressionByType(progressionType)
    const nextStage = getNextStage(currentStage, seconds, progression)

    // TODO: extract function
    const timerProgressToMinutes = React.useCallback(
      (seconds: Seconds) => {
        const closestProgressionDiscrete = getFloorProgressionDiscrete(
          seconds,
          progression
        )
        const nextStage = getNextStage(
          closestProgressionDiscrete,
          seconds,
          EXPONENTIAL_STAGES
        )

        const currentStage =
          seconds < closestProgressionDiscrete
            ? nextStage
            : closestProgressionDiscrete

        return currentStage
      },
      [getNextStage, progression]
    )

    React.useEffect(() => {
      if (seconds) {
        const currentStage = timerProgressToMinutes(seconds)
        setCurrentStage(currentStage)
      }
    }, [seconds, timerProgressToMinutes])

    React.useEffect(() => {
      if (seconds === 0) {
        setCurrentStage(0)
      }
    }, [currentStage, seconds])

    const nextStageInMinutes: Minutes = React.useMemo(
      () => Math.floor(nextStage / 60),
      [nextStage]
    )

    const timerUnits = React.useMemo(
      () => (nextStageInMinutes === 1 ? "minute" : "minutes"),
      [nextStageInMinutes]
    )

    const article = React.useMemo(
      () => (nextStageInMinutes === 1 ? "is" : "are"),
      [nextStageInMinutes]
    )

    return (
      <Wrapper>
        {isTimerStarted ? (
          <>
            <StyledTrianle />

            <StageWrapper>
              <StageNumber>{nextStageInMinutes}</StageNumber>
              <StyledUnits>{timerUnits}</StyledUnits>
              <StyledDesc>{article}&nbsp;up&nbsp;next</StyledDesc>
            </StageWrapper>
          </>
        ) : (
          <StyledTip>Press the button to start</StyledTip>
        )}
      </Wrapper>
    )
  }
)
