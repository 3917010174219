import { Seconds } from "shared/types"

export const getFloorProgressionDiscrete = (
  seconds: Seconds,
  progression: Seconds[]
) => {
  const progDiscrete = progression.reduce(
    (acc, progressionNum) => getClosestDiscrete(acc, progressionNum, seconds),
    0 as Seconds
  )

  return progDiscrete
}

const getClosestDiscrete = (
  acc: Seconds,
  progNum: Seconds,
  seconds: Seconds
) => {
  const curDiff = Math.abs(progNum - seconds)
  const prevDiff = Math.abs(acc - seconds)
  const isLessThanMinutes = seconds >= progNum

  const isCloserToDiscrete = curDiff < prevDiff
  const closestDiscrete = isCloserToDiscrete ? progNum : acc

  return isLessThanMinutes ? closestDiscrete : acc
}
