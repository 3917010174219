import { Seconds } from "shared/types"

// eslint-disable-next-line max-statements
export default function getRemainTime(
  closestDiscreteStage: Seconds,
  secondsProgress: Seconds,
  progression: Seconds[]
) {
  const nextStageIndex = progression.indexOf(closestDiscreteStage) + 1
  const nextStage = progression[nextStageIndex]
  const closestStage =
    secondsProgress < closestDiscreteStage ? closestDiscreteStage : nextStage
  const secondsToNextStage: Seconds = closestStage - secondsProgress

  const minutesRemain = Math.floor(secondsToNextStage / 60)
  const secondsRemain = secondsToNextStage - minutesRemain * 60
  return { seconds: secondsRemain, minutes: minutesRemain }
}
