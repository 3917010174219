import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FEATURE_NAME } from "../settings.constants"
import { ProgressionType, SettingsState } from "../settings.types"

const initialState: SettingsState = {
  darkMode: true,
  timerBlinking: true,
  progression: ProgressionType.DEFAULT,
  sound: true,
  progressionRatio: 5
}

export const settingsSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode
    },
    toggleTimerBlinking: (state) => {
      state.timerBlinking = !state.timerBlinking
    },
    toggleSound: (state) => {
      state.sound = !state.sound
    },
    setProgression: (
      state,
      action: PayloadAction<SettingsState["progression"]>
    ) => {
      state.progression = action.payload
    },
    setProgressionRatio: (
      state,
      action: PayloadAction<SettingsState["progressionRatio"]>
    ) => {
      state.progressionRatio = action.payload
    },
    setSettings: (state, action: PayloadAction<SettingsState>) => {
      state.darkMode = action.payload.darkMode
      state.timerBlinking = action.payload.timerBlinking
      state.sound = action.payload.sound
      state.progression = action.payload.progression
      state.progressionRatio = action.payload.progressionRatio
    }
  }
})

export const settingsActions = {
  ...settingsSlice.actions
}

export const settingsSliceReducer = settingsSlice.reducer
